<template>
	<div>
		<validation-observer ref="simpleRules">
			<form-wizard
				color="#296db4"
				:title="null"
				:subtitle="null"
				finish-button-text="Submit"
				back-button-text="Previous"
				skip-button-text="Skip"
				class="steps-transparent mb-3"
				@on-complete="addFarm()"
			>
				<!-- Poultrac detail tab -->
				<tab-content title="Farm Details" icon="feather icon-file-text">
					<b-row>
						<b-col cols="12" class="mb-2">
							<div class="d-flex">
								<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />

								<div>
									<h5 class="mb-0">Farm Details</h5>
									<small class="text-muted"> Enter Your Farm Details. </small>
								</div>
							</div>
						</b-col>
						<b-col md="6">
							<b-form-group label="Farm Name" label-for="i-username">
								<validation-provider #default="{ errors }" name="Farm Name" rules="required">
									<b-form-input name="farm_name" v-model="farmData.farm_name" placeholder="" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mb-2 mt-5">
							<h5 class="mb-0">Address Information</h5>
						</b-col>
						<b-col md="12">
							<b-form-group label="Address" label-for="address">
								<validation-provider #default="{ errors }" name="Address" rules="required">
									<b-form-textarea name="address" v-model="farmData.address" placeholder="" rows="3" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group label="Country" label-for="country">
								<v-select
									v-model="farmData.selectedCountry"
									:options="countryList"
									value-field="id"
									label="name"
									@input="getState"
								/>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group label="State" label-for="state">
								<v-select
									v-model="farmData.selectedState"
									:options="stateList"
									value-field="id"
									label="name"
									@input="getCity"
								/>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group label="City" label-for="city">
								<v-select v-model="farmData.selectedCity" :options="cityList" label="name" value-field="id" />
							</b-form-group>
						</b-col>
						<!-- 
						<b-col md="6">
							<b-form-group label="Pincode" label-for="pincode">
								<validation-provider
									#default="{ errors }"
									name="Pincode"
									:rules="{ required: true, min: 4, max: 6, integer }"
								>
									<b-form-input name="pincode" type="text" v-model="farmData.pin_code" placeholder="Pin Code" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col> -->
					</b-row>
				</tab-content>
			</form-wizard>
		</validation-observer>
	</div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import shedUpdate from "@/libs/hatcheryUpdate";

export default {
	components: {
		FormWizard,
		TabContent,
		vSelect,
	},
	directives: {
		Ripple,
	},
	mixins: [heightTransition],
	data() {
		return {
			items: [
				{
					id: 0,
					prevHeight: 0,
				},
			],
			companyId: null,
			selectRole: null,
			selectName: null,
			roleWiseList: null,
			nextTodoId: 2,
			stateList: [],
			countryList: [],
			cityList: [],
			userList: [],
			company_id: null,
			companyRoles: [],
			farmData: {
				farm_name: null,
				pin_code: null,
				address: null,
				selectedState: null,
				selectedCountry: null,
				selectedCity: null,
				assign_user: [
					{
						role: null,
						name: null,
					},
				],
			},
		};
	},
	mounted() {
		this.initTrHeight();
	},
	created() {
		this.companyId = this.$route.params.companyId;
		window.addEventListener("resize", this.initTrHeight);
		this.getCountry();
		this.getSelectedRole();
	},

	destroyed() {
		window.removeEventListener("resize", this.initTrHeight);
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		roleLabel(option) {
			return option.name;
		},
		userLabel(option) {
			return option.name;
		},
		getSelectedRole() {
			let companyId = this.companyId;
			let url = "";
			if (companyId) {
				url = `web/company/${companyId}/roles`;
			} else {
				url = `web/company/roles`;
			}
			// make api call to get list of role
			axiosIns
				.get(url)
				.then((response) => {
					this.companyRoles = response.data.companyRoles;
				})
				.catch((error) => {
					error;
				});
		},
		getSelectedRolesName(slug, index) {
			let companyId = this.companyId;
			let url = "";
			if (companyId) {
				url = `web/company/${companyId}/getUser/${slug}`;
			} else {
				url = `web/getRoleUser/${slug}`;
			}
			axiosIns
				.get(url)
				.then((response) => {
					this.$set(this.userList, index, response.data.unAssignedUsers);
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		repeatAgain() {
			let newRole = {
				role: null,
				name: null,
			};
			this.farmData.assign_user.push(newRole);

			this.$nextTick(() => {
				this.trAddHeight(this.$refs.row[0].offsetHeight);
			});
			this.$nextTick(() => {
				this.trAddHeight(this.$refs.row[0].offsetHeight);
			});
		},
		removeItem(index) {
			this.farmData.assign_user.splice(index, 1);
			// this.trTrimHeight(this.$refs.row[0].offsetHeight);
		},
		initTrHeight() {
			this.trSetHeight(null);
		},
		getState(event) {
			axiosIns
				.get(`web/get-state-list?country_id=${event.id}`)
				.then((response) => {
					this.stateList = response.data.state_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getCountry(event) {
			axiosIns
				.get(`web/get-country-list`)
				.then((response) => {
					this.countryList = response.data.country_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getCity(event) {
			axiosIns
				.get(`web/get-city-list?state_id=${event.id}`)
				.then((response) => {
					this.cityList = response.data.city_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addFarm(e) {
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					let formData = new FormData();
					let form_data = this.objectToFormData(this.farmData, formData);
					let companyId = this.companyId;
					let url = "";
					if (companyId) {
						url = `web/company/${companyId}/add-farm`;
					} else {
						url = `web/add-farm`;
					}
					axiosIns
						.post(url, form_data)
						.then((res) => {
							const data = res.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: data,
								},
							});
							shedUpdate.$emit("shedUpdateEvent", companyId);
							this.$router.push({
								path: `/apps/farm/list`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							// (data, "data");
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.repeater-form {
	overflow: hidden;
	transition: 0.35s height;
}
.role-button {
	background-color: rgba(41, 109, 180, 1);
	border-color: rgba(41, 109, 180, 1);
	color: white;
	width: 170px;
	display: flex;
	padding: 10px 10px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}
@import "@core/scss/vue/pages/nav-role.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
